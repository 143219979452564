import { Form, Formik } from "formik";

interface ScheduleMeetingValues {
  meetingTitle: string;
  description: string;
}

const ScheduleMeetingForm = ({ closeModal }: { closeModal: () => void }) => {
  const formikInitialValues = {
    meetingTitle: "",
    description: ""
  };

  const handleSubmit = (values: ScheduleMeetingValues) => {
    console.log(values);
  };

  return (
    <Formik initialValues={formikInitialValues} onSubmit={handleSubmit}>
      <Form className="flex flex-col gap-4 py-4">
        <label htmlFor="Meeting I.D" className="text-black text-[16px]">
          Conference Title
          <input
            type="text"
            name="meetingId"
            placeholder="My Meeting"
            className="p-2 mt-2 border border-gray-100 rounded-md w-full"
          />
        </label>
        <label htmlFor="Name" className="text-black text-[16px]">
          Description
          <textarea
            rows={5}
            cols={50}
            name="description"
            placeholder="Add Description"
            className="p-2 mt-2 border border-gray-100 rounded-md w-full"
          ></textarea>
        </label>
        <label htmlFor="Name" className="text-black text-[16px]">
          When
          <div className="flex justify-between">
            <input
              name="date"
              type="date"
              className="p-2 mt-2 mr-[20px] border border-gray-100 rounded-md w-full"
            />
            <input
              type="time"
              name="time"
              className="p-2 mt-2 border border-gray-100 rounded-md w-full"
            />
          </div>
          <div className="flex mt-2">
            <input
              type="text"
              name="repeat"
              className="p-2 mt-2 mr-[20px] w-1/3 border border-gray-100 rounded-md w-full"
            />
            <label className="text-black mt-5">
              <input type="checkbox" className="mr-2" />
              All day
            </label>
          </div>
        </label>
        <div className="flex justify-between pt-8">
          <button
            className="bg-white text-gray-200 border-2 border-gray-200 rounded-md p-2"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button className="bg-green text-white p-2 rounded-md">Save Conferencing</button>
        </div>
      </Form>
    </Formik>
  );
};

export default ScheduleMeetingForm;
