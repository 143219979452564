import { SVGProps } from "react";
/* eslint-disable prettier/prettier */
type Props = {
  size?: number;
  color?: string;
  className?: string;
} & SVGProps<SVGSVGElement>;
const SvgZuSettings = ({ size, color, className, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M10 6.5c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5Zm0 6A2.503 2.503 0 0 1 7.5 10c0-1.378 1.122-2.5 2.5-2.5s2.5 1.122 2.5 2.5-1.121 2.5-2.5 2.5Zm9.082-.331A2.519 2.519 0 0 1 17.83 10a2.513 2.513 0 0 1 1.255-2.172.833.833 0 0 0 .302-1.136l-1.672-2.886a.831.831 0 0 0-1.135-.303 2.519 2.519 0 0 1-2.519-.005 2.505 2.505 0 0 1-1.246-2.161.837.837 0 0 0-.837-.836H8.023a.837.837 0 0 0-.837.836 2.507 2.507 0 0 1-3.764 2.166.834.834 0 0 0-1.136.303L.612 6.694a.832.832 0 0 0 .309 1.137 2.516 2.516 0 0 1 1.251 2.165 2.512 2.512 0 0 1-1.255 2.175.83.83 0 0 0-.303 1.137l1.671 2.885a.834.834 0 0 0 1.137.304 2.515 2.515 0 0 1 2.508 0l.002.001.006.004a2.502 2.502 0 0 1 1.244 2.162c0 .461.375.836.836.836h3.959a.837.837 0 0 0 .836-.835 2.51 2.51 0 0 1 3.765-2.167.834.834 0 0 0 1.136-.303l1.674-2.889a.833.833 0 0 0-.306-1.137Zm-2.15 3.382a3.526 3.526 0 0 0-3.373.085 3.513 3.513 0 0 0-1.741 2.864h-3.64a3.507 3.507 0 0 0-1.738-2.864 3.526 3.526 0 0 0-3.372-.086L1.56 12.948c1-.642 1.614-1.76 1.611-2.956A3.526 3.526 0 0 0 1.56 7.05l1.508-2.603a3.523 3.523 0 0 0 3.373-.086 3.51 3.51 0 0 0 1.74-2.863h3.637a3.513 3.513 0 0 0 1.743 2.865c1.035.6 2.311.629 3.371.084l1.508 2.602A3.509 3.509 0 0 0 16.831 10a3.522 3.522 0 0 0 1.61 2.947l-1.509 2.604Z"
        fill={color}
      />
    </svg>
  );
};
SvgZuSettings.defaultProps = {
  size: 16,
  color: "currentColor"
};
export default SvgZuSettings;
