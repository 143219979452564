import { ZuYoutube, ZuShareLink, ZuPlusCircle } from "components/icons";
const BroadcastChannelView = () => {
  return (
    <div>
      <div className="bg-[#fff] h-[52px] rounded-t-2xl text-center flex items-center">
        <h1 className="text-lg font-bold px-4 py-3.5">
          Connect your social accounts to livestream your videos
        </h1>
      </div>
      <div className="flex gap-10 mt-10 flex-wrap justify-center sm:justify-start">
        <div className="py-4 px-4  grid grid-cols-2 gap-10 bg-[#fff]  rounded-lg w-full sm:w-[270px] shadow-sm">
          <div>
            <div className="h-16 w-16 border-2 border-[#E2E8F0] rounded-full flex justify-center items-center">
              <ZuYoutube size={32} color="#FF0000" strokeColor="#fff" />
            </div>
            <p className="text-lg font-bold font-Heebo text-[#334155] mt-5">Youtube</p>
            <div className="w-[200px] mt-3 text-gray-500">
              <p className="text-base font-Heebo inline-block mr-1">youtube.com/studio</p>
              <ZuShareLink size={15} className="inline-block" />
            </div>
          </div>
          <div className="w-[80px] h-6 bg-[#D1FAE5] grid place-items-center rounded-3xl justify-self-end">
            <p className="text-base capitalize text-[#10B981] font-normal">Connected</p>
          </div>
        </div>

        <div className="self-center cursor-pointer">
          <div className="flex items-center justify-center">
            <ZuPlusCircle size={50} color="#10B981" strokeColor="#fff" />
          </div>
          <p className="capitalize text-lg text-[#10B981] mt-2 text-center">
            Connect more accounts
          </p>
        </div>
      </div>
    </div>
  );
};

export default BroadcastChannelView;
