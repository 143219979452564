import { PricingTab } from "components/layouts/pricing";

const PricingView = () => {
  return (
    <div>
      <PricingTab />
    </div>
  );
};

export default PricingView;
