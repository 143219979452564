// exports all icon files from here
const SVG_PATH = "/svg";
const ICON_PATH = "/icons";

export const logo = `${SVG_PATH}/logo.svg`;
export const logoIcon = `${SVG_PATH}/logo-icon.svg`;
export const star = `${SVG_PATH}/star.svg`;
export const plus = `${ICON_PATH}/plus-square.png`;
export const calendarplus = `${ICON_PATH}/calendar-plus-alt.png`;
export const video = `${ICON_PATH}/video.png`;
export const link = `${ICON_PATH}/link.png`;
export const play = `${SVG_PATH}/play.svg`;
export const close = `${ICON_PATH}/close.svg`;
export const paperclip = `${SVG_PATH}/paperclip.svg`;
export const timesCircle = `${SVG_PATH}/timesCircle.svg`;
export const volume = `${SVG_PATH}/volume.svg`;
export const rotateRight = `${SVG_PATH}/rotateright.svg`;
export const vectorOne = `${SVG_PATH}/vector-one.svg`;
export const vectorTwo = `${SVG_PATH}/vector-two.svg`;
export const vectorThree = `${SVG_PATH}/vector-three.svg`;
export const ClockIcon = `${SVG_PATH}/clock.svg`;
export const moreHorizontalIcon = `${SVG_PATH}/more-horizontal.svg`;

export const ranking = `${SVG_PATH}/ranking.svg`;
export const presentation = `${SVG_PATH}/presentation-text.svg`;
export const telescope = `${SVG_PATH}/telescope.svg`;

export const productEllipse = `${SVG_PATH}/products-ellipse.svg`;
export const productEllipse2 = `${SVG_PATH}/products-ellipse2.svg`;
