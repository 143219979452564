import {
  ZuUserProfile,
  ZuLockPassword,
  ZuBills,
  ZuFiles,
  ZuSecurity,
  ZuMoreCircle
} from "components/icons";

import { useState } from "react";
import { Billings, ChangePassword, File, Others, Profile, Security } from "./pages";

export const tabsComponents = [
  {
    Icon: <ZuUserProfile />,
    name: "Profile",
    component: <Profile />
  },
  {
    Icon: <ZuLockPassword />,
    name: "Password",
    component: <ChangePassword />
  },
  {
    Icon: <ZuBills />,
    name: "Billing",
    component: <Billings />
  },
  {
    Icon: <ZuFiles />,
    name: "Files",
    component: <File />
  },
  {
    Icon: <ZuSecurity />,
    name: "Security",
    component: <Security />
  },
  {
    Icon: <ZuMoreCircle />,
    name: "Others",
    component: <Others />
  }
];

const SettingsView = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div className="flex overflow-x-auto">
        {tabsComponents.map((tab, index) => (
          <button
            key={index}
            className={`flex-1 p-4 text-left ${
              activeTab === index ? "text-green-500 border-b-2 border-green-500" : "text-gray-200"
            }`}
            onClick={() => setActiveTab(index)}
          >
            <div className="flex items-center text-gray-200">
              {tab.Icon}
              <span className="ml-2 text-gray-200">{tab.name}</span>
            </div>
          </button>
        ))}
      </div>
      <hr className="w-full h-px bg-gray-100 shadow" />
      <div className="p-4">{tabsComponents[activeTab].component} </div>
    </div>
  );
};

export default SettingsView;
