import { userimg1, userimg2, userimg3, userimg4 } from "assets/images";

export const UserImgData = [
  {
    id: 1,
    userImgUrl: userimg1
  },
  {
    id: 2,
    userImgUrl: userimg2
  },
  {
    id: 3,
    userImgUrl: userimg3
  },
  {
    id: 4,
    userImgUrl: userimg4
  },
  {
    id: 5,
    userImgUrl: userimg1
  },
  {
    id: 6,
    userImgUrl: userimg2
  }
];
