interface IURL {
  REDIRECT_URL: string;
  API_BASE_URL: string;
  MEETING_BASE_URL: string;
}

const dev = {
  REDIRECT_URL: "https://localhost:3000/",
  API_BASE_URL: "https://localhost:5000/api",
  MEETING_BASE_URL: "https://localhost:4443" // TODO: use HTTPS once SSL is configured
};

const prod = {
  REDIRECT_URL: `${window.location.origin}/`,
  API_BASE_URL: "https://backend.zumaridiafrica.com/api",
  MEETING_BASE_URL: `${window.location.protocol}//meet.${window.location.hostname}`
};

const getEnv = (): IURL => {
  switch (process.env.NODE_ENV) {
    case "development":
      if (process.env.REACT_APP_ENVIRONMENT) {
        return prod;
      }
      return dev;
    case "production":
      return prod;
    default:
      return dev;
  }
};

export const env = getEnv();
