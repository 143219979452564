import { Dashboard } from "components";
import ReferAFriendView from "./ReferAFriendView";
import { useAppSelector } from "hooks";

export const ReferAFriendContainer = () => {
  const referralMessage = `Achieve more with the Zumaridi Video Conferencing!
  Trust me, you won't regret it!`;

  const { user } = useAppSelector((state) => state.auth.user);

  const refId: string = user?.refId;

  const url = window.location.href.includes(refId)
    ? window.location.href
    : window.location.href + "/" + refId;

  return (
    <Dashboard>
      <ReferAFriendView referral={url} title={referralMessage} userData={user} />
    </Dashboard>
  );
};
