export const fileData = {
  totalStorage: 120000,
  data: [
    {
      date: "12/10/2023",
      description: "CEO & Developers",
      format: "MP4",
      usedSpace: 3000,
      id: 0
    },
    {
      date: "10/22/2022",
      description: "Frontend Engineers",
      format: "MP3",
      usedSpace: 4763,
      id: 1
    },
    {
      date: "12/22/2022",
      description: "AI Team",
      format: "MP4",
      usedSpace: 1424,
      id: 2
    },
    {
      date: "11/08/2022",
      description: "Product Designers",
      format: "MP4",
      usedSpace: 3227,
      id: 3
    },
    {
      date: "11/12/2022",
      description: "Engineering Department",
      format: "MP4",
      usedSpace: 4758,
      id: 4
    }
  ]
};
