import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid"; // needed for showing time
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { useState } from "react";
import ScheduleMeetingForm from "components/modules/forms/ScheduleMeetingForm";
import CalendarModal from "components/modules/modals/CalendarModal";

const CalendarView = ({ allEvents, isLoading }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  // const handleDateClick = () => {
  //   setIsOpen(true);
  // };

  const closeModal = () => {
    setIsOpen(false);
    console.log("closed modal");
  };

  // const events: any[] = [
  //   {
  //     title: "Meeting with Jake",
  //     start: "2023-03-27T08:20",
  //     end: "2023-03-27T10:00"
  //   },
  //   {
  //     title: "Ada's Submittion",
  //     start: "2023-03-26T12:10",
  //     end: "2023-03-26T01:00"
  //   },
  //   {
  //     daysOfWeek: ["3"],
  //     title: "Meeting with all employees",
  //     startTime: "10:45",
  //     endTime: "12:45",
  //     color: "#ff00003b"
  //   }
  // ];

  return (
    <div>
      {isLoading ? (
        <p className="flex justify-center">Loading Calendar...</p>
      ) : (
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridDay"
          headerToolbar={{
            left: "prev,title,next",
            right: "timeGridDay,timeGridWeek,dayGridMonth"
          }}
          titleFormat={{ year: "numeric", month: "long" }}
          views={{
            dayGridMonth: {
              dayHeaderFormat: { weekday: "short" }
            },
            timeGridWeek: {
              dayHeaderFormat: { weekday: "short", day: "numeric" }
            },
            timeGridDay: {
              dayHeaderFormat: { weekday: "long", day: "numeric" }
            }
          }}
          events={allEvents}
          eventBorderColor={"#C7E8D2"}
          eventTextColor={"green"}
          eventBackgroundColor={"#C7E8D2"}
          selectable={true}
          // dateClick={handleDateClick}
        />
      )}
      {isOpen && (
        <CalendarModal
          title="Schedule A Meeting"
          content={<ScheduleMeetingForm closeModal={closeModal} />}
          isOpen={isOpen}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default CalendarView;
