import { ZUimages } from "assets";
const ContactHero = () => {
  return (
    <div
      className="bg-no-repeat bg-cover"
      style={{ backgroundImage: `url('${ZUimages.ContactUs}')` }}
    >
      <div className=" relative h-96 w-full bg-cover bg-center md:p-24 px-5 flex flex-col gap-12 justify-center">
        <h3 className="text-white md:text-6xl text-[28px] font-bold">Contact Us</h3>
        <p className="text-white mt-3 md:text-3xl text-lg font-light">
          Have any questions? We’d love to hear from you
        </p>
      </div>
    </div>
  );
};

export default ContactHero;
