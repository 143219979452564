/* eslint-disable @typescript-eslint/require-array-sort-compare */
import { ZuBroadcast, ZuDelete, ZuFilter, ZuCalendar, ZuFiles } from "components/icons";
import ConfirmDeleteModal from "components/modules/modals/ConfirmDeleteModal";
import { useState } from "react";
import { fileData } from "utils/filesData";

// Schemas and interfaces may still be subject to change as project requirements evolve over time.

interface Data {
  date?: string;
  description?: string;
  format?: string;
  usedSpace?: number;
  id?: number;
}

interface ModalSettings {
  showDeleteModal: boolean;
  itemName?: string;
  itemId?: number;
}

enum SortStyle {
  CHRONOLOGICALLY = "date",
  BY_ALPHABETICAL_ORDER = "alphabet",
  USED_SPACE = "space"
}

const FileView = () => {
  const [data, setData] = useState<Data[]>(fileData.data); // Remember, this is set to some dummy data at the moment.
  const [modalSettings, setModalSettings] = useState<ModalSettings>({
    showDeleteModal: false
  }); // More about this from line 135 to line 137
  const [meetingListOrder, setMeetingListOrder] = useState<SortStyle>(SortStyle.CHRONOLOGICALLY);

  const usedStorage = data.reduce((acc, cur) => {
    if (cur.usedSpace) {
      return acc + cur.usedSpace;
    }
    return 0;
  }, 0); // This varible tracks the combined usedSpace of all recorded meetings in the list. Check line 169 for implementation
  const usedStoragePercentage: number = Math.round((usedStorage / fileData.totalStorage) * 100);
  const usedStorageAngle: number = Math.round((usedStoragePercentage / 100) * 360); // This converts the fraction of available storage used into degrees to display on a pie chart. Check line 220

  const handleItemDelete = (id: number | undefined) => {
    setData((prev) => prev.filter((item) => item.id !== id));
    // Add an API call here to handle this change in the database as well
  };

  const orderMeetings = (style: SortStyle) => {
    const newArr: Data[] = [];

    // Check the sortStyle
    if (style === "alphabet") {
      // Sort the a new array of the parameter in question (In this case it is the description being sorted alphabetically).
      const descArr = data.map((item) => item.description);
      const sortedDescArr = descArr.sort();
      sortedDescArr.forEach((item) => {
        for (let i = 0; i < data.length; i++) {
          if (item === data[i].description) {
            newArr.push(data[i]);
          }
        }
      });
      setData(newArr);
      setMeetingListOrder(style); // Set the order style to handle UI logic from line 261 to line 302
    }

    // The following 2 "if" blocks follow the same procedure as the first.
    if (style === "date") {
      const dateArr = data.map((item) => item.date && new Date(item.date).valueOf());
      const sortedDateArr = dateArr.sort((a, b) => {
        if (a && b) {
          return a - b;
        }
        return 0;
      });
      sortedDateArr.forEach((item) => {
        for (let i = 0; i < data.length; i++) {
          if (item === new Date(data[i].date ?? 0).valueOf()) {
            newArr.push(data[i]);
          }
        }
      });
      newArr.reverse();
      setData(newArr);
      setMeetingListOrder(style);
    }

    if (style === "space") {
      const usedSpaceArr = data.map((item) => item.usedSpace);
      const sortedUsedSpaceArr = usedSpaceArr.sort((a, b) => (a && b && a - b) ?? 0);
      sortedUsedSpaceArr.forEach((item) => {
        for (let i = 0; i < data.length; i++) {
          if (item === data[i].usedSpace) {
            newArr.push(data[i]);
          }
        }
      });
      newArr.reverse();
      setData(newArr);
      setMeetingListOrder(style);
    }
  };

  // the "tableRows" array displays, well, table rows. Each array item is used to display data in the UI as follows
  const tableRows = data?.map((item, index) => {
    if (item.description && item.date && item.format && item.usedSpace) {
      const date = new Date(item.date);
      return (
        <tr
          className={`${
            index % 2 === 0 ? "bg-green-500/30 hover:bg-green-500/60" : "bg-white hover:bg-gray-100"
          } group transition shadow-black-100 hover:shadow-lg`}
          key={index}
        >
          <td className="text-slate-600 hidden md:table-cell ml-5">
            {index + 1 < 10 ? `0${index + 1}` : index + 1}
          </td>
          <td className="text-slate-600">
            {date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}/{date.getMonth() + 1}/
            {date.getFullYear()}
          </td>
          <td className="whitespace-nowrap sm:hidden relative">
            {item.description.slice(0, 9).trim()}...
            <div className="absolute p-1 -top-4 -left-5 rounded-md border border-green-500 bg-white hidden transition-colors group-hover:table-cell group-active:table-cell">
              {item.description}
              <div className="flex gap-3 justify-center mr-4">
                <ZuDelete
                  className="hover:scale-150 transition w-3 md:w-4 shrink-0"
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalSettings({
                      showDeleteModal: true, // Shows the delete modal
                      itemName: item.description, // Passes down the item name as props to be displayed in the modal's message
                      itemId: item.id // Passes down the ID of the item to be deleted as props.
                    });
                  }}
                />
                <ZuBroadcast className="hover:scale-150 transition w-3 md:w-4 shrink-0 text-slate-600" />
              </div>
            </div>
          </td>
          <td className="hidden sm:table-cell lg:hidden relative">
            {item.description.slice(0, 30).trim()}
            {item.description.length > 30 ? "..." : ""}
            <div className="absolute p-1 -top-4 -left-5 rounded-md border border-green-500 bg-white hidden transition-colors group-hover:table-cell group-active:table-cell">
              {item.description}
              <div className="flex gap-3 justify-center">
                <ZuDelete
                  className="hover:scale-150 transition w-3 md:w-4 shrink-0"
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalSettings({
                      showDeleteModal: true,
                      itemName: item.description,
                      itemId: item.id
                    });
                  }}
                />
                <ZuBroadcast className="hover:scale-150 transition w-3 md:w-4 shrink-0 text-slate-600" />
              </div>
            </div>
          </td>
          <td className="hidden lg:table-cell">{item.description}</td>
          <td>{item.format}</td>
          <td>
            {/* Logic for either dislaying usedSpace of each item in MB or GB */}
            {item.usedSpace > 1024
              ? `${(item.usedSpace / 1024).toFixed(2)}GB`
              : `${item.usedSpace}MB`}
          </td>
          <td className="hidden lg:table-cell">
            <div className="flex gap-7 justify-center">
              <ZuDelete
                className="hover:scale-150 transition w-3 md:w-4 shrink-0"
                onClick={(e) => {
                  e.stopPropagation();
                  setModalSettings({
                    showDeleteModal: true,
                    itemName: item.description,
                    itemId: item.id
                  });
                }}
              />
              <ZuBroadcast className="hover:scale-150 transition w-3 md:w-4 shrink-0 text-slate-600" />
            </div>
          </td>
        </tr>
      );
    } else {
      return (
        // Fallback for corrupt data.
        <tr key={index}>
          <td className="text-black">Error loading this meeting</td>
        </tr>
      );
    }
  });

  return (
    <section>
      {modalSettings.showDeleteModal && (
        <ConfirmDeleteModal
          setModalSettings={setModalSettings}
          {...modalSettings}
          handleItemDelete={() => handleItemDelete(modalSettings.itemId)}
        />
      )}
      <div className="flex flex-col md:flex-row justify-around py-[30px] md:py-[70px]">
        <h5 className="py-2 font-bold">
          Manage all your files here,
          <br /> broadcast or delete saved meetings
        </h5>
        <div className="flex w-fit self-start justify-between pr-4 bg-white items-center p-2 gap-7 font-bold rounded-lg">
          <div
            className="relative w-[60px] aspect-square rounded-full"
            style={{
              backgroundImage: `conic-gradient(rgb(22 163 74) ${usedStorageAngle}deg, #F1F1F1 0)`,
              display: "block"
            }}
          />
          <div>
            <h5 className="text-lg">Total: {fileData.totalStorage / 1000}GB</h5>
            <p className="text-md">Used: {usedStoragePercentage}%</p>
          </div>
        </div>
      </div>
      <div className="relative w-fit container mx-auto">
        <table className="table-fixed text-left w-full rounded-t-lg overflow-hidden sm:max-w-[524px] lg:max-w-[710px] xl:max-w-[1000px]">
          <thead className="bg-green-600 rounded-t-lg">
            <tr>
              <th className="md:w-[40px] lg:w-[50px] xl:[100px] hidden md:table-cell"></th>
              <th className="sm:w-[80px] md:w-[100px] lg:w-[120px] xl:w-[160px]">Date</th>
              <th className="sm:w-[180px] lg:w-[250px] xl:w-[435px]">Description</th>
              <th className="sm:w-[50px] md:w-[70px] xl:w-[100px]">Format</th>
              <th className="rounded-tr-lg sm:w-[50px] md:w-[70px] xl:w-[100px]">Size</th>
            </tr>
          </thead>
          <tbody className="overflow-y-scroll">
            {data.length === 0 ? (
              <tr className="bg-green-500/30 absolute top-8 md:top-11 h-8 md:h-10 w-full flex justify-center items-center">
                <td>Nothing to see here...</td>
              </tr>
            ) : (
              tableRows
            )}
          </tbody>
        </table>
        {data.length !== 0 && (
          <button
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="md:w-[10%] lg:w-[20%] xl:w-[10.5%] py-2 box-border px-4 border-2 border-green-600 rounded-lg md:rounded-l-none lg:absolute lg:right-0 lg:top-0 lg:bottom-auto flex justify-center items-center gap-2 bg-white fixed bottom-5 right-5 top-auto cursor-pointer group z-10"
          >
            <ZuFilter size={12} color="#07AA3D" className="shrink-0" />
            <p>Filter</p>
            <div className="absolute scale-0 group-focus:flex group-focus:scale-100 bg:white border border-green-500 -top-16 -right-1 gap-5 items-center px-5 py-3 w-auto bg-white z-20 transition-all rounded-lg flex-nowrap h-12 cursor-default">
              <div
                title="Order chronologically"
                className={`scale-0 group-focus:scale-100 cursor-pointer p-1 rounded-full ${
                  meetingListOrder === SortStyle.CHRONOLOGICALLY && "bg-green-600"
                }`}
              >
                <ZuCalendar
                  className={`${meetingListOrder === SortStyle.CHRONOLOGICALLY && "text-white"}`}
                  onClick={() => orderMeetings(SortStyle.CHRONOLOGICALLY)}
                  size={20}
                />
              </div>
              <div
                title="Order alphabetically"
                className={`scale-0 group-focus:scale-100 cursor-pointer p-1 rounded-full ${
                  meetingListOrder === SortStyle.BY_ALPHABETICAL_ORDER && "bg-green-600"
                }`}
              >
                <p
                  className={`${
                    meetingListOrder === SortStyle.BY_ALPHABETICAL_ORDER && "text-white"
                  } whitespace-nowrap`}
                  onClick={() => orderMeetings(SortStyle.BY_ALPHABETICAL_ORDER)}
                  style={{
                    fontSize: "15px"
                  }}
                >
                  A-Z
                </p>
              </div>
              <div
                title="Order by size"
                className={`scale-0 group-focus:scale-100 cursor-pointer p-1 rounded-full ${
                  meetingListOrder === SortStyle.USED_SPACE && "bg-green-600"
                }`}
              >
                <ZuFiles
                  className={`${meetingListOrder === SortStyle.USED_SPACE && "text-white"}`}
                  onClick={() => orderMeetings(SortStyle.USED_SPACE)}
                  size={20}
                />
              </div>
            </div>
          </button>
        )}
      </div>
    </section>
  );
};

export default FileView;
