import { Landing } from "components";
import ProductsView from "./ProductsView";

export const ProductsContainer = () => {
  return (
    <Landing>
      <ProductsView />
    </Landing>
  );
};
