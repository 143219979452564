import { Dashboard } from "components";
import CalendarView from "./CalendarView";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { useGetAllMeetingsQuery } from "services/meetings.service";
import { ScheduleMeetingValues } from "types";

export const CalendarContainer = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [allEvents, setAllEvents] = useState<ScheduleMeetingValues[] | undefined>([]);
  const { data, isLoading } = useGetAllMeetingsQuery(user.user.email);

  useEffect(() => {
    const all = data?.DATA;
    const scheduleForLater = all?.filter(
      (meeting: ScheduleMeetingValues) => meeting.meetingType === "future"
    );
    setAllEvents(scheduleForLater);
  }, [data]);

  return (
    <Dashboard>
      <CalendarView allEvents={allEvents} isLoading={isLoading} />
    </Dashboard>
  );
};
