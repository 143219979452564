import { SVGProps } from "react";
/* eslint-disable prettier/prettier */
type Props = {
  size?: number;
  color?: string;
  className?: string;
} & SVGProps<SVGSVGElement>;
const SvgZuHand = ({ size, color, className, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M21.145 9.865c-1.014-.374-2.461.128-3.486 1.343-.673.797-.87 1.933-1.754 2.051-.338.045-.521-.126-.617-.374-.1-.417-.078-1.062.108-1.963l2.01-7.555c.16-.577-.214-1.136-1.117-1.318-.902-.182-1.581.188-1.742.765l-1.795 6.85c-.361 1.512-1.032 1.238-.87-.476l.514-8.032c.042-.62-.529-1.128-1.373-1.155-.843-.027-1.452.397-1.495 1.017l-.49 8.225c-.105 1.661-.816 1.035-.97-.168l-1.14-6.654c-.117-.628-.821-1.062-1.665-.949-.844.114-1.354.697-1.237 1.325l1.097 6.081c.435 2.322-.134 2.965-1.07.444l-1.17-3.696c-.19-.593-.89-.961-1.768-.755-.878.206-1.257.797-1.067 1.39l1.143 3.474c.478 2.5.478 3.488.478 5.21S2.616 22 9.863 22c7.246 0 8.736-6.153 8.805-6.543 0 0 .09-.908.849-1.758a37.59 37.59 0 0 1 2.293-2.371c.232-.22.458-1.047-.665-1.463Z"
        fill="currentColor"
      />
      <path
        d="M17.092 14.458c-.24-.082-.443-.292-.467-.75-1.515.174-3.563.529-5.14 1.818-2.046 1.672-2.395 3.48-2.378 4.689.014.975.65 1.437.837-.008.234-1.825 1.826-5.45 7.148-5.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
SvgZuHand.defaultProps = {
  size: 16,
  color: "currentColor"
};
export default SvgZuHand;
