import { ZuZumaridiLogo } from "components/icons";

interface ModalSettings {
  showDeleteModal: boolean;
  itemName?: string;
  itemId?: number;
}

interface ConfirmDeleteProps extends ModalSettings {
  setModalSettings: React.Dispatch<React.SetStateAction<ModalSettings>>;
  handleItemDelete: () => void;
}

const ConfirmDeleteModal = ({
  showDeleteModal,
  setModalSettings,
  itemName,
  handleItemDelete
}: ConfirmDeleteProps) => {
  return (
    <div
      className={`${
        !showDeleteModal && "hidden"
      } fixed inset-0 bg-opacity-40 bg-black z-20 flex items-center justify-center text-center`}
      onClick={(e) => {
        e.stopPropagation();
        setModalSettings((prev) => ({ ...prev, showDeleteModal: false }));
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="relative p-4 bg-white rounded-lg space-y-4 w-[300px] sm:w-[380px] text-md sm:text-lg"
      >
        <div className="flex justify-center">
          <ZuZumaridiLogo width={130} />
        </div>
        <div>
          <h5 className="text-md sm:text-lg">
            Are you sure you want to delete the "
            <i className="font-bold">
              {itemName && itemName?.length > 20 ? `${itemName?.slice(0, 20)}...` : `${itemName}`}
            </i>
            " meeting?
            <br />
            This action cannot be reversed!
          </h5>
        </div>
        <div className="flex justify-around">
          <button
            className="bg-green-500 text-white px-5 py-2 rounded-md hover:scale-105 hover:shadow-md transition-all"
            onClick={() => {
              handleItemDelete();
              setModalSettings((prev) => {
                return { ...prev, showDeleteModal: false };
              });
            }}
          >
            Confirm
          </button>
          <button
            className="px-5 py-2 rounded-md border-2 border-green-500/30 hover:scale-105 hover:shadow-md hover:bg-green-500/60 transition-all"
            onClick={() =>
              setModalSettings((prev) => {
                return { ...prev, showDeleteModal: false };
              })
            }
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
